var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.crons
    ? _c(
        "div",
        { staticClass: "health-checks-wrapper" },
        [
          _vm._l(_vm.crons, function (cron) {
            return [
              _c("div", { key: cron.id + "status", staticClass: "status" }, [
                _c("p", { class: cron.status }, [
                  _vm._v(_vm._s(_vm._f("formatStatus")(cron.status))),
                ]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.pingTimeTooltip(cron),
                      expression: "pingTimeTooltip(cron)",
                    },
                  ],
                  key: cron.id + "info",
                  staticClass: "info",
                },
                [
                  _c("p", { staticClass: "cron-name" }, [
                    _vm._v(_vm._s(cron.name)),
                  ]),
                  _c("p", { staticClass: "cron-desc" }, [
                    _vm._v(_vm._s(cron.desc)),
                  ]),
                ]
              ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }